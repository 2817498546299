import {
  getProductList,
  inStock,
  inStockSignle,
  findDetail,
  exportDate,
  getImg
} from "./api";
import navUtil from "@/components/global/CustomizeNav/util";
import Util from "@/utils/utils";
import {
  backCheckproxysign,
  downContractById,
  sendLadingContract,
  dmsSendLadingContract,
  sumbitAttorney,
} from "@/views/order/hisenseProcurementProgress/api";
import { publicPath } from "@/common/constant";
const BASE_API = publicPath; // 地址
export default {
  data() {
    return {
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },

      {
        path: "/dmspurchaseProgress",
        name: "",
        title: "渠道订单汇总"
      }
      ],
      total: 0,
      tableWidth: 1188,
      showTotalColumns: ['出库数量', '签收数量', '下单数量', '金额', '审核通过数量', '发货单数量', '发货单金额', '甩货数量', '入库数量'],
      tcolumns: [{
        label: "采购订单编码",
        prop: "documentNum",
        ellipsis: true,
        sortable: 'custom',
        sortName: "po.document_num",
        slot: "documentNum",
        width: 200
      },
      {
        label: "订单创建日期",
        prop: "createDate",
        ellipsis: true,
        sortable: 'custom',
        sortName: "po.document_date",
        width: 200
      },
      {
        label: "订单状态",
        prop: "statusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "订单类型",
        prop: "orderTypeName",
        ellipsis: true,
        width: 200
      },
      {
        label: "关联的销售单号",
        prop: "salesNum",
        ellipsis: true,
        width: 200
      },
      {
        label: "供应商编码",
        prop: "supplierCode",
        ellipsis: true,
        width: 200
      },
      {
        label: "供应商名称",
        prop: "supplierName",
        ellipsis: true,
        width: 200
      },
      {
        label: "销售组织",
        prop: "orgName",
        ellipsis: true,
        width: 200
      },
      {
        label: "物料组",
        prop: "materialGroupName",
        ellipsis: true,
        width: 160
      },
      {
        label: "型号",
        prop: "model",
        ellipsis: true,
        width: 160
      },
      {
        label: "颜色",
        prop: "colour",
        ellipsis: true,
        width: 200
      },

      {
        label: "质量等级",
        prop: "invStatusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "补差类型",
        prop: "invStatusTypeName",
        // key: "invStatusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "下单数量",
        prop: "orderedQty",
        // key: "totalOrderedQty",
        ellipsis: true,
        align: 'right',
        width: 200
      },
      {
        label: "金额",
        prop: "totalAmount",
        // key: "totalAmount",
        ellipsis: true,
        align: 'right',
        width: 200
      },
      {
        label: "期望送货日期",
        prop: "edt",
        // key: "edt",
        ellipsis: true,
        width: 200
      },
      {
        label: "订单审核日期",
        prop: "acknowledgedDate",
        // key: "acknowledgedDate",
        ellipsis: true,
        width: 200
      },
      {
        label: "审核通过数量",
        prop: "acknowledgedTotalOrderedQty",
        // key: "acknowledgedTotalOrderedQty",
        ellipsis: true,
        align: 'right',
        width: 200
      },
      {
        label: "发货单号",
        prop: "stvDocumentNum",
        ellipsis: true,
        slot: "stvDocumentNum",
        width: 200
      },
      // {
      //   label: "发货单创建时间",
      //   prop: "stvCreateDate",
      //   ellipsis: true,
      //   width: 200,
      // },
      {
        label: "发货单状态",
        prop: "stvStatusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "发货仓库",
        prop: "gicOutWarehouseName",
        ellipsis: true,
        width: 200
      },
      {
        label: "发货质量等级",
        prop: "soiInvStatusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "发货补差类型",
        prop: "soiInvStatusTypeName",
        ellipsis: true,
        width: 200
      },
      {
        label: "发货单金额",
        prop: "invAmount",
        ellipsis: true,
        align: 'right',
        width: 200
      },
      {
        label: "发货单数量",
        prop: "bDemandQty",
        align: 'right',
        ellipsis: true,
        width: 200
      },
      {
        label: "出库数量",
        prop: "outBActualQty",
        align: 'right',
        ellipsis: true,
        width: 200
      },
      {
        label: "签收数量",
        prop: "signQty",
        ellipsis: true,
        align: 'right',
        width: 200
      },
      {
        label: "甩货数量",
        prop: "shQty",
        align: 'right',
        ellipsis: true,
        width: 200
      },
      {
        label: "入库数量",
        prop: "inBActualQty",
        align: 'right',
        ellipsis: true,
        width: 200
      },

      {
        label: "发货日期",
        prop: "stvCreateDate",
        ellipsis: true,
        width: 200
      },
      {
        label: "物流状态",
        prop: "omsStatusName",
        ellipsis: true,
        width: 200
      },
      {
        label: "退货状态",
        prop: "returnStatusName",
        ellipsis: true,
        width: 160,
      },
      {
        label: "预计送达日期",
        prop: "reserveTime",
        ellipsis: true,
        width: 200
      },
      {
        label: "签收日期",
        prop: "signDate",
        ellipsis: true,
        width: 200
      },
      {
        label: "配送方式",
        prop: "deliveryTypeName",
        ellipsis: true,
        width: 200
      },
      {
        label: "自提委托书",
        prop: "attorneyLetter",
        ellipsis: true,
        slot: "attorneyLetter",
        width: 200
      },
      {
        label: "收货地址",
        prop: "address",
        ellipsis: true,
        width: 200
      },
      {
        label: "联系人-联系电话",
        prop: "contac",
        ellipsis: true,
        width: 200
      },
      {
        label: "下单平台",
        prop: "dataSourceValue",
        ellipsis: true,
        width: 200
      },
      {
        label: "下单用户",
        prop: "customizedcreator",
        ellipsis: true,
        width: 200
      },
      {
        label: "活动名称",
        prop: "activityName",
        ellipsis: true,
        width: 160,
      }, 
      // 添加字段
      {
        label: "参与活动单号",
        prop: "userActivityCode",
        ellipsis: true,
        width: 160,
      },
      {
        label: "活动类型",
        prop: "discountTypeName",
        ellipsis: true,
        width: 160,
      },
      {
        label: "组合编码",
        prop: "packageCode",
        ellipsis: true,
        width: 160,
      },
      {
        label: "产品分组编码",
        prop: "productGroup",
        ellipsis: true,
        width: 160,
      },
      {
        label: "产品分组说明",
        prop: "productGroupRemark",
        ellipsis: true,
        width: 160,
      },
      {
        label: "比例",
        prop: "packageRateNum",
        ellipsis: true,
        width: 160,
      },


        {
        label: "操作",
        prop: "",
        key: "btnGroup",
        slot: "btnGroup",
        fixed: 'right',
        width: 140
      }
      ],
      tableData: [],
      cherkInfo: null,
      tabelIsLoading: false,
      //x 最大宽度 y 最大高度
      scroll: {
        x: 2100
      },
      modelDataConfirm: [], // 查询结果
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        sortName: "",
        sortOrder: ""
      },
      loading: false,
      modelShow: false,
      modelShowSign: false,
      infoinStock: {},
      ssearchList: {
        pageNo: 1,
        size: 10
      },
      pager: {
        count: "",
        pageNo: 1,
        pageSize: 10
      },
      stock: [],
      stockData: [],
      tablecolumns: [{
        title: "仓库",
        dataIndex: "name",
        key: "name"
      }],
      fileterForm: {
        status: "",
      },
      selKeys: null,
      selKeys2: null,
      selRows: [],
      orderLoading: false,
      imgURL: [],
      onluyOneMessage: false,
      modelShopShow: false,
      shopKey: '',// 门店id
      showCK: true,
      ztVisible: false,
      sqVisible: false, // 授权弹窗
      scanVisible: false, // 弹出二维码弹窗
      InfoTips: false,
      mesageInfoTips: '',
      contractId:'', // 授权书id
      pageLoadFlag:false,
      isLoading:false
    };
  },

  mounted() {
    // 如果是子账户不显示操作栏
    if (this.$store.state.user.userInfo.account.type !== 'main') {
      this.tcolumns.pop()
    }
    // 导航数据
    this.navList = navUtil.orderList;

  },
  methods: {
    // 点击自提委托书打印
    linkClickZT(obj) {
      //
      if(obj.link) {
        window.open(BASE_API+`/orderLadingContract/downContractById.api?contractId=`+ obj.link,'_blank')
        // let data = {
        //   serviceCode:'downContractById',
        //   contractId:obj.link
        // }
        // // 点击自提委托书  根据自提委托书的link 调用接口 获取pdf的url 直接打开
        // downContractById(data).then(res=>{
        //   console.log(res);
        //   if(res.data.code == 0) {
        //     window.open(res.data.data.url,'_blank')
        //   } else {
        //     this.$message.warning(res.data.msg)
        //   }
        // })
      }
    },
    // 打开字体委托书的弹窗
    openZT(){
      // 如果没勾选 弹出当前提示
      if(this.selRows.length <= 0 ) {
        this.$message.warning('请先选择订单')
        return
      }
      // TODO 如果勾选数据 不满足后端返回的ladingOperateFlag == Y 就给提示 ladingOperateFlag:是否可以操作签署委托书
      // const OperateFlagRows =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y')
      // const OperateFlagRowsNum =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y').map(it=>it.documentNum).join(',')
      // if(OperateFlagRows.length > 0) {
      //   this.mesageInfoTips = (`${OperateFlagRowsNum}不能进行签署自提委托书的操作，请重新选择`)
      //   this.InfoTips = true
      //   return
      // }
      //TODO 如果勾选的数据不是自提 提示 BHO202212310131，BHO202212310312的配送方式不是自提，无需签署自提委托书。 it.shareFlagName是否是统仓 嘉赫未提供是否是统仓 需要修改
      const inconformitySelRows =  this.selRows.filter(it => (it.soDeliveryMode !== '01' && it.soDeliveryMode !== '10') )
      const inconformitySelRowsNum =  this.selRows.filter(it => it.soDeliveryMode !== '01' && it.soDeliveryMode !== '10').map(it=>it.documentNum).join(',')
      if(inconformitySelRows.length > 0) {
        this.mesageInfoTips = (`${inconformitySelRowsNum}的配送方式不是自提，无需签署自提委托书`)
        this.InfoTips = true
        return
      }
      //  如果物流状态是创建失败提示信息
      const failSelRows =  this.selRows.filter(it => it.omsStatus == 'CREATE_FAIL')
      const failsapSelRowsNum =  this.selRows.filter(it =>it.omsStatus !== 'CREATE_FAIL' ).map(it=>it.documentNum).join(',')
      if(failSelRows.length > 0) {
        this.mesageInfoTips = (`${failsapSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }
      // 发货单状态 已失效
      const stvlSelRows =  this.selRows.filter(it => it.stvStatus == 'freeze')
      const stvsapSelRowsNum =  this.selRows.filter(it =>it.stvStatus == 'freeze' ).map(it=>it.documentNum).join(',')
      if(stvlSelRows.length > 0) {
        this.mesageInfoTips = (`${stvsapSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }

      //  如果订单状态!== 'finished'
      const intransitSelRows =  this.selRows.filter(it => it.status !== 'finished' )
      const intransitSelRowsNum =  this.selRows.filter(it =>it.status !== 'finished' ).map(it=>it.documentNum).join(',')
      if(intransitSelRows.length > 0) {
        this.mesageInfoTips = (`${intransitSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }

      // TODO（4）如果勾选的订单已经签了委托书，弹窗示：“BHO202212310131，BHO202212310312已签署自提委托书，请勿重复签署。 是否签署委托书后端接口提供 嘉赫暂未提供
      const signSelRows =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2 ))
      const signSelRowsNum =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2) ).map(it=>it.documentNum).join(',')
      if(signSelRows.length > 0) {
        this.mesageInfoTips = (`${signSelRowsNum}已签署自提委托书，请勿重复签署`)
        this.InfoTips = true
        return
      }

      this.ztVisible = true
    },
    // 获取填写的自提委托书的信息
    getztInfo(Info){
      let proArr = this.selRows.map(item => {
        return {
          itemId: item.poItemId,
         };
      })
      let data = {
        itemIds: proArr,
        carName: Info.name,
        carPhone: Info.phone,
        carNumber: Info.carNum,
        carId: Info.idCard,
        orderFrom:'P',
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
      }
      this.pageLoadFlag = true
      // 提交以后接口返回是否授权 返回是的话不展示去授权弹窗  返回否的话就展示授权弹窗 进行下面的操作
      dmsSendLadingContract( data ).then( res => {
        let resData =  JSON.parse(res.data);
        if( resData.code == '1' ){
          this.pageLoadFlag = false
          this.$message.warning( resData.msg )
        } else {
          this.pageLoadFlag = false
          if(resData.data.type == 0) {
            this.$message.success(resData.msg)
            this.pageLoadFlag = false
            this.contractId = resData.data.contractId
            //   授权弹窗
            this.sqVisible = true
            this.ztVisible = false
          } else {
            this.$message.warning(resData.msg)
            this.contractId =''
            this.ztVisible = false
            this.pageLoadFlag = false
            this.getData()
          }
        }
      })
    },
    // 获取是否签署数据
    async getsqInfo(data){
      //  data 上个页面传过来的数据 1 去签署  0 不签署
      // 如果是点击去签署 就关闭当前页面 打开二维码页面
      if(data == 1){
        this.scanVisible = true
        this.getData()
      }
      this.isLoading = true
      let dataA = {
        pickType: data,
        contractId:this.contractId,
      }
      backCheckproxysign(dataA).then(res=>{
        if(res.code == 1) {
          this.$message.warning(res.data.msg)
          this.getData()
          this.contractId = ''
          this.isLoading = false
        } else {
          this.contractId = ''
          this.isLoading = false
          this.getData()
        }
      })
    },
    // 二维码页面关闭页面
    getscanInfo(){
      this.getData()
    },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        var realData = JSON.parse(res.data);
        if (realData.data) {
          this.imgURL = realData.data;
          this.onluyOneMessage = true;
        } else {
          this.$message.warning('暂无回单影像');
        }

      })
    },
    exportClick() {
      this.orderLoading = true;
      let paramList = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: "",

          ...this.fileterForm,
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pagination.sortName,
          sortOrder: this.pagination.sortOrder
        }
      }
      exportDate(paramList).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == '0') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '渠道进度汇总列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    // 单笔入库
    getReason(value,) {
      this.selKeys2 = value
      this.inSignle()
    },
    // 批量入库--不选择门店
    getChooseId(value) {
      this.selKeys = value
      this.allIn()
    },
    // 批量入库--选择门店
    getChooseShop(value) {
      this.selKeys = value.ck
      this.shopKey = value.shop
      this.allIn2()

    },
    // 批量入库 -- 选择门店
    // 打开批量入库弹窗发
    openStock() {
      // 如果勾选数据 it.canHandleBatchInboun !== 1 就给提示 canHandleBatchInbound:是否可以操作批量入库
      const OperateFlagRows =  this.selRows.filter(it => it.canHandleInbound !== '1' )
      const OperateFlagRowsNum =  this.selRows.filter(it => it.canHandleInbound !== '1').map(it=>it.documentNum).join(',')
      if(OperateFlagRows.length > 0) {
        this.mesageInfoTips = (`${OperateFlagRowsNum}不能进行批量入库的操作，请重新选择`)
        this.InfoTips = true
        return
      }
      if (this.selRows.length > 0) {
        let arrFour = []
        let elseArr = []
        for (let i = 0; i < this.selRows.length; i++) {
          if (this.selRows[i].deliveryTypeCode == '04') {
            arrFour.push(this.selRows[i])
          }
          if (this.selRows[i].deliveryTypeCode !== '04') {
            elseArr.push(this.selRows[i])
          }
        }
        if (arrFour.length == this.selRows.length) {
          this.modelShopShow = true;
        }
        if (elseArr.length == this.selRows.length) {
          this.modelShow = true;
        }
        if (elseArr.length !== this.selRows.length && arrFour.length !== this.selRows.length) {
          this.$message.warning('直配到用户订单不能和其他订单一起入库,请重新选择')
        }
      } else {
        this.$message.warning('请选择数据')
      }
    },

    // 批量入库 -- 添加门店参数
    allIn2() {
      let wId = [];
      this.selRows.forEach(item => {
        wId.push(item.id);
      });
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        warehouseId: this.selKeys,
        directOrderIds: [],
        storeCode: this.shopKey,
        purchaseOrderIds: wId
      };
      inStock(data).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShow = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShow = false;
        }
      });
    },
    // 批量入库
    allIn() {
      let wId = [];
      this.selRows.forEach(item => {
        wId.push(item.id);
      });
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        warehouseId: this.selKeys,
        directOrderIds: [],
        storeCode: "",
        purchaseOrderIds: wId
      };
      inStock(data).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShow = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShow = false;
        }
      });
    },
    // 打开单笔入库弹窗
    async openStock2(data) {
      this.modelShowSign = true;
      this.infoinStock = data;
      // 查询当前选择的列表详情接口
      let adata = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        loginType: "CS",
        purchaseOrderId: data.id
      };
      let res = await findDetail(adata);
      let request = JSON.parse(res.data);
      this.cherkInfo = request.data;
    },
    // 单个入库修改仓库选项
    onChangeSign(selKeys, selRows) {
      this.selKeys2 = selRows[0].id;
    },
    // 单个入库
    inSignle() {
      this.cherkInfo.purchaseOrderItem.forEach(item => {
        // 入库数量
        item.shippedBqty = item.outQty > 0 ? item.outQty - (Number(item.orderedQty) - item.waitStockBQty) : 0;
        item.warehouseId = this.selKeys2;
      });
      let Finished = null
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty == this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = true
      }
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty < this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = false
      }

      inStockSignle({
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        data: {
          isFinished: Finished,
          purchaseOrderId: this.cherkInfo.id,
          purchaseOrderItem: this.cherkInfo.purchaseOrderItem
        }
      }).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShowSign = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShowSign = false;
        }
      });
    },
    // 查看待入库数据
    onFineshedchange(e) {
      if (e.target.checked == true) {
        this.tabelIsLoading = true;
        this.fileterForm.status = "finished";
        const data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          terms: {
            model: this.searchIpt,
            colour: "",
            warehouseId: "",
            invStatusId: "",
            isLock: "",
            dealerMaterialGroupFlag: "",
            materialGroupCode: "",
            materialGroupName: "",
            gicWarehouseType: "",
            invStatusType: "",
            invType: "",
            bigQtyMin: 0,
            orgId: "",
            ...this.fileterForm,
            ...this.terms
          },
          page: {
            pageNo: 1,
            pageSize: 10,
            sortName: this.pagination.sortName,
            sortOrder: this.pagination.sortOrder
          }
        };
        getProductList(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.tableData = data.data;
          tableData.forEach(it => {
            it.contac = it.contact + "-" + it.contactInfo;
            it.uid = it.id;
            it.attorneyLetter = ''
            if(it.ladingStatus == '2') {
              it.attorneyLetter = it.link
            }
            if( it.ladingStatus == 1 ) {
              it.attorneyLetter = '签署中'
            }

          });
          tableData = data.data;
          let tableData;
          tableData.forEach(it => {
            it.contac = it.contact + "-" + it.contactInfo;
            it.uid = it.id;
            it.attorneyLetter = ''
            if(it.ladingStatus == '2') {
              it.attorneyLetter = it.link
            }
            if( it.ladingStatus == 1 ) {
              it.attorneyLetter = '签署中'
            }
          });
          this.pagination.pageNo = data.page.pageNo;
          this.pagination.pageSize = 10;
          this.pagination.count = data.page.totalCount;
          this.total = data.page.totalCount;
          this.tabelIsLoading = false;
          this.tableData = tableData
        });
      } else {
        this.fileterForm.status = "";
        this.getData();
      }
    },
    // 查看列表数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: "",
          ...this.fileterForm,
          ...this.terms
        },
        page: {
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize,
          sortName: this.pagination.sortName,
          sortOrder: this.pagination.sortOrder
        }
      };
      getProductList(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        tableData = data.data;
        let tableData;
        tableData.forEach(it => {
          it.contac = it.contact + "-" + it.contactInfo;
          it.uid = it.id;
          it.attorneyLetter = ''
          if(it.ladingStatus == '2') {
            it.attorneyLetter = it.link
          }
          if( it.ladingStatus == 1 ) {
            it.attorneyLetter = '签署中'
          }
        });
        this.pagination.pageNo = data.page.pageNo;
        this.pagination.pageSize = 10;
        this.pagination.count = data.page.totalCount;
        this.total = data.page.totalCount;
        this.tabelIsLoading = false;
        this.tableData = tableData
      });
    },
    // 点击采购订单跳转
    linkClick(obj) {
      let routeUrl = this.$router.resolve({
        path: "/channelOrder/detail",
        query: {
          id: obj.id
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 销售订单跳转
    salrorderLink(data) {
      let routeUrl = this.$router.resolve({
        path: "/channelOrder/progress",
        query: {
          id: data.id
        }
      })
      window.open(routeUrl.href, "_blank");
    },

    // 合计
    getSummariesFun(param) {
      const {
        columns,
        data
      } = param;
      let result = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (!obj[data[i].documentNum]) {
          result.push(data[i]);
          obj[data[i].documentNum] = true;
        }
      }
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (!this.showTotalColumns.includes(column.label)) {
          return;
        }
        if (column.label == '下单数量' || column.label == '金额') {
          const values = result.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位

          } else {
            sums[index] = ""; //计算存在nan单位
          }
        } else {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位
          } else {
            sums[index] = ""; //计算存在nan单位
          }
        }
      });

      return sums;
    },
    //表格全选
    rowSelection(rows) {
      this.selRows = rows;
    },
    // 表格方法 如果为true 就可以勾选 为false就不能
    checkSelectable(row) {
      return row.canHandleBatchInbound == '1'
    },
    // 仓库分页（前段分页）
    pagesiezes(data) {
      var start = (data - 1) * this.pager.pageSize;
      this.stockData = this.stock.slice(start, start + this.pager.pageSize);
      this.pager.pageSize;
    },
    // 分页
    pageSearch(pager) {
      this.pagination.pageNo = pager.pageNo;
      this.pagination.pageSize = pager.pageSize;
      this.getData();
    },
    // 搜索
    searchFun(arg) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.terms = arg[1];
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.tcolumns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.pagination.sortOrder =
          sorter.order == "ascending" ?
            "asc" :
            sorter.order == "descending" ?
              "desc" :
              "";
        this.pagination.sortName = sortName[0].sortName;
      } else {
        this.pagination.sortOrder = "";
        this.pagination.sortName = "";
      }
      this.getData();
    }
  }
};